@font-face {
    font-family: "WienerMelange_Fallback";
    font-display: swap;
    font-weight: 400;
    src: url(../fonts/WienerMelange_W_Rg.woff2) format("woff2"), url(../fonts/WienerMelange_W_Rg.woff) format("woff");
}

@font-face {
    font-family: "WienerMelange_Fallback";
    font-display: swap;
    font-weight: 600;
    src: url(../fonts/WienerMelange_W_Bd.woff2) format("woff2"), url(../fonts/WienerMelange_W_Bd.woff) format("woff");
}

@font-face {
    font-family: "WienerMelange_Fallback";
    font-display: swap;
    font-weight: 800;
    src: url(../fonts/WienerMelange_W_ExBd.woff2) format("woff2"), url(../fonts/WienerMelange_W_ExBd.woff) format("woff");
}

@supports (font-variation-settings: normal) {
    @font-face {
        font-family: "WienerMelange";
        font-display: swap;
        src: url(../fonts/WienerMelangeVF_W_Wght.woff2) format("woff2-variations"), url(../fonts/WienerMelangeVF_W_Wght.woff2) format("woff2");
    }
}

@font-face {
    font-family: 'Wiener Melange';
    src: local('Wiener Melange'), local('WienerMelangeVF'), url('./fonts/53dd562938ad99fc5f51c435aa88b8d5.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

.wm-modal__image-icon {
    background: #f3f1ef url(../icons/css/zoom.svg) no-repeat center right .5rem;
}